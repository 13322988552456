* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  :root {
    --primaryColor: #0a0a0a;
    --mainWhite: rgb(255, 255, 255);
    --offWhite: rgb(255, 255, 255);
    --mainBlack: rgb(0, 0, 0);
    --mainTransition: all 0.3s linear;
    --mainSpacing: 3px;
    --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
    --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  }
  /* globals */
  body {
    padding-top: 66px;
    color: var(--mainBlack);
    background: var(--mainWhite);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 1.4;
  }
  h1 {
    font-size: 3em;
    line-height: 1;
    margin-bottom: 0.5em;
  }
  h2 {
    font-size: 2em;
    margin-bottom: 0.75em;
  }
  h3 {
    font-size: 1.5em;
    line-height: 1;
    margin-bottom: 1em;
  }
  h4 {
    font-size: 1.2em;
    line-height: 1.25;
    margin-bottom: 1.25em;
  }
  h5 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1.5em;
  }
  h6 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1.5em;
  }
  
  .btn-primary {
    display: inline-block;
    text-decoration: none;
    letter-spacing: var(--mainSpacing);
    color: var(--mainBlack);
    background: transparent;
    padding: 0.4rem 0.9rem;
    border: 3px solid var(--primaryColor);
    transition: var(--mainTransition);
    text-transform: uppercase;
    cursor: pointer;
  }
  .btn-primary:hover {
    background: var(--mainWhite);
    color: var(--primaryColor);
  }
  .loading {
    text-transform: capitalize;
    text-align: center;
    margin-top: 3rem;
  }
  .error {
    text-align: center;
    text-transform: uppercase;
    margin: 2rem 0;
  }
  .empty-search {
    text-align: center;
    text-transform: capitalize;
    margin: 2rem 0;
    padding: 1rem;
    letter-spacing: var(--mainSpacing);
  }
  /* end of globals */
  /* Navbar */
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.75rem 2rem;
    background: var(--offWhite);
    z-index: 1;
  }
  .nav-header {
    display: flex;
    justify-content: space-between;
  }
  .nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  .nav-icon {
    font-size: 2.5rem;
    color: var(--primaryColor);
  }
  /* hiding by dafault */
  .nav-links {
    height: 0;
    overflow: hidden;
    transition: var(--mainTransition);
    list-style-type: none;
    font-family: 'Rajdhani', sans-serif;
  }
  /* showing up by action */
  .nav-links a {
    display: block;
    text-decoration: none;
    padding: 1rem 0;
    color: var(--mainBlack);
    transition: var(--mainTransition);
    text-align: center;
    font-size:1.3rem;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    letter-spacing: var(--mainSpacing);
  }
  .nav-links a:hover {
  
    text-shadow: rgb(152, 152, 152) 2px 2px 2px;
    font-family: 'Press Start 2P', cursive;
  }
  
  .show-nav {
    height: 100px;
  }
  @media screen and (min-width: 768px) {
    .nav-btn {
      display: none;
    }
    .nav-center {
      max-width: 1170px;
      margin: 0 auto;
      display: flex;
    }
    
    .nav-links {
      height: auto;
      display: flex;
      margin-left: 4rem;
    }
    .nav-links a {
      margin: 0 1rem;
      padding: 0.5rem 0;
    }
  }
  /* end of navbar */
/* Hero */
.defaultHero,
.solutionsHero {
  min-height: calc(100vh - 66px);
  background: url("./img/hero2.svg") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solutionsHero {
  background-image: url("./img/world_map_PNG25.png");
  min-height: 75vh;
}
/* End of Hero */
/* Banner */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(193, 191, 191, 0.434);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
  width: 100%;
}
.banner h1 {
  font-size: 2.5rem;
  text-shadow: var(--primaryColor) 2px 2px 2px;
}
.banner div {
  width: 25rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 2.8rem auto;
}
.banner h4 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  text-shadow: var(--mainWhite) 1px 1px 1px;
  color:var(--primaryColor);
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */

/* Title */
.section-title {
  text-align:center;
  margin-bottom: 4rem;
  margin-left: 2rem;
}
.section-title h4 {
  font-size: 3.5rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
  text-shadow: gray 2px 2px 2px ;
}
.section-title div {
  width: 25rem;
  height: 2px;
  margin: 0 auto;
  background: gray;
}
/* end of Title */

/* services */
.services {
  padding: 5rem 0;
}
.services {
  background: var(--mainBlack);
  text-align: left;
  color: var(--mainWhite);
  font-family: 'Times New Roman';
  font-size: 17px;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  letter-spacing: var(--mainSpacing);
}
.services p {
  width: 80%;
  margin: 0 auto;
}


.slider{
  position: relative;
  width: 100%;
  height: 70vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: black;
}
.slide{
  min-width: 100%;
  height: 80%;
  transition: 0.5s;
  overflow: hidden;
}

#goLeft{
position: absolute;
top: 50%;
left: 1fr;
transform: translateY(-50%);
width: 10%;
height: 80%;
background: none;
border: none;
outline: none;
transition: 0.5s;
font-size:2vw;
}
#goLeft:hover{
  background: rgba(0, 0, 0, 0.331);
  cursor:pointer;
  color: var(--mainWhite);
}
#goRight{
  position: absolute;
  top: 50%;
  right:0;
  transform: translateY(-50%);
  width: 10%;
  height: 80%;
  background: none;
  border: none;
  outline: none;
  transition: 0.5s;
  font-size:2vw; 
}
#goRight:hover{
  background: rgba(0, 0, 0, 0.331);
  cursor:pointer;
  color: var(--mainWhite);
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */

/*partners*/
.partners {
  padding: 5rem 0;
  color: var(--mainWhite);
}
.partners {
  background: url(./img/last.jpg)center/cover no-repeat;
  text-align: center;
}
.partners-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}


@media screen and (min-width: 992px) {
  .partners-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .partners p {
    width: 100%;
  }
}
/* partner ended */

/* contact page */
.data{
  padding: 1.5rem 0;
  color:var(--mainBlack);
  font-family: 'Times New Roman';
  font-size: larger;
  text-shadow: gray 1px 1px 1px;
}
.data {
  background:var(--mainWhite);
  text-align: left;
}
.data-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}


@media screen and (min-width: 992px) {
  .data-center {
    width: 95vw;
    max-width: 1170px;
  }
}
.loading{
  text-shadow: gray 1px 1px 1px;
}